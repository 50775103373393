import React from "react"
import PropTypes from "prop-types"
import "../assets/scss/main.scss"

const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === "/") {
    content = (
      <>
        <a href="#main" className="skip-link">
          Skip to main content
        </a>
        <div>{children}</div>
      </>
    )
  } else {
    content = (
      <>
        <a href="#main" className="skip-link">
          Skip to main content
        </a>
        <div id="wrapper" className="page">
          <div>{children}</div>
        </div>
      </>
    )
  }

  return <>{content}</>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

export default Layout
