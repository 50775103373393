import React from "react"
import Layout from "./src/components/layout"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
  }

  // Font loading optimization
  if (typeof window !== "undefined") {
    // Optimization for Repeat Views
    if (sessionStorage.fontsLoaded) {
      document.documentElement.classList.add("fonts-loaded")
      return
    }

    // Font Loading API
    if ("fonts" in document) {
      Promise.all([
        document.fonts.load("1em Roboto"),
        // Add other font weights/styles here
      ]).then(() => {
        document.documentElement.classList.add("fonts-loaded")
        sessionStorage.fontsLoaded = true
      })
    }
  }
}
